import { graphql, PageProps } from "gatsby"
import React from "react"
import "twin.macro"

import {
  Article,
  BackgroundImage,
  Headings,
  Section
} from "@/atoms"
import { BreadCrumbs, Layout, NextSEO, SEO } from "@/components"
import { WorkPageQuery } from "@/gatsby-graphql"
import { Works as works } from "@/constants/text";
import tw from "twin.macro"

export type WorkPageProps = PageProps<WorkPageQuery>

const Work: React.FC<WorkPageProps> = ({ data }) => {
  return (
    <Layout>
      {/* <SEO title="業務内容" /> */}
      <NextSEO
        type="Article"
        title="業務内容"
        description={"よくある相談内容"}
        url="/work"
        breadCrumbs={[{ 'title': '業務内容' }]}
      />
      <BackgroundImage
        image={data.placeholderImage.childImageSharp.gatsbyImageData}
        header={<Headings variant="h2" as='h1'>業務内容</Headings>}
      />
      <Article maxWidth={'tablet'} css={{
        ...tw`space-y-12 pb-12 px-4`,
        '@tablet': { ...tw`px-0` }
      }}>
        <BreadCrumbs breadCrumbs={[{ title: "事業内容" }]} />
        <Section css={{ ...tw`space-y-6` }} >
          <Headings variant='h2' as='h1' >主な相談内容</Headings>
          <div css={{
            ...tw`flex flex-col space-y-6`,
            '@tablet': {
              ...tw`flex-row space-y-0 space-x-6`,
            }
          }} >
            {Object.values(works).map((ele, i) => {
              return (
                <ul key={i} tw="list-disc list-inside space-y-2">
                  {ele.list.map((ele, i) => <li key={i}>{ele}</li>)}
                </ul>
              )
            })}
          </div>
        </Section>
      </Article>
    </Layout>
  )
}

export default Work

export const data = graphql`
    query WorkPage {
        placeholderImage: file(relativePath: { eq: "image4.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [WEBP]
            )
          }
        }
    }
  `
